@forward 'styles/global';
@forward 'styles/material';
@use 'material-icons/iconfont/material-icons.css';


@use '@adnova/jf-ng-components/config' as *;

@layer bootstrap, material, base, generic, component, app;

@layer bootstrap {
  @import 'bootstrap/scss/bootstrap';
}